
document.addEventListener("DOMContentLoaded", () => {

    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(
        popoverTriggerEl));

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(
        tooltipTriggerEl));

    var elements = document.getElementsByClassName("information-box");

    var myFunction = function (element) {
        var legendPrimary = element.querySelector('legend.information-box-title-primary');
        if (legendPrimary !== null) {
            legendPrimary.addEventListener('click', event => {
                var content = element.querySelector('.fieldset-content');
                if (content.style.display === "none") {
                    content.style.display = "block";
                } else {
                    content.style.display = "none";
                }
            })
        }

        var legendWarning = element.querySelector('legend.information-box-title-warning');
        if (legendWarning !== null) {
            legendWarning.addEventListener('click', event => {
                var content = element.querySelector('.fieldset-content');
                if (content.style.display === "none") {
                    content.style.display = "block";
                } else {
                    content.style.display = "none";
                }
            })
        }
    };

    for (var i = 0; i < elements.length; i++) {
        myFunction(elements[i]);
        elements[i].addEventListener('click', myFunction, false);
    }

});

// client side validation for only numbers
window.validateAcceptOnlyNumbers = function (event) {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode == 13  //enter
        || event.keyCode == 8 // tab
        || event.keyCode == 9  //backspace
        || event.keyCode == 46 //delete
        || event.keyCode == 37 // left arrow
        || event.keyCode == 39 // right arrow
        || event.keyCode == 16 // shift
    ) {
        return true;
    }//only accept numbers 
    else if (key < 48 || key > 57) {
        return false;
    } else return true;
};


